import aiCarousel from '../assets/components/aiCarousel.png';
import articleShowcase from '../assets/components/articleShowcase.png';
import Audience from '../assets/components/audience.png';
import casesShowcase from '../assets/components/casesShowcase.png';
import commitment from '../assets/components/commitment.png';
import contact from '../assets/components/contact.png';
import events from '../assets/components/events.png';
import faq from '../assets/components/faq.png';
import introduction from '../assets/components/introduction.png';
import markdown from '../assets/components/markdown.png';
import mission from '../assets/components/mission.png';
import partnersSlider from '../assets/components/partnersSlider.png';
import peopleShowcase from '../assets/components/peopleShowcase.png';
import product from '../assets/components/product.png';
import programs from '../assets/components/programs.png';
import promo from '../assets/components/promo.png';
import quoteSlider from '../assets/components/quoteSlider.png';
import result from '../assets/components/result.png';
import simpleSlider from '../assets/components/simpleSlider.png';
import support from '../assets/components/support.png';
import supportWorld from '../assets/components/supportWorld.png';
import Testimonials from '../assets/components/testimonials.png';
import title from '../assets/components/title.png';

const BTN_COLORS = [
  {
    label: 'Dark',
    value: 'dark',
  },
  {
    label: 'Light',
    value: 'light',
  },
  {
    label: 'Blue',
    value: 'primary',
  },
  {
    label: 'Red',
    value: 'secondary',
  },
];

const FORM_FIELD = {
  name: 'form',
  type: 'object',
  label: 'Form',
  schema: [
    {
      name: 'portalId',
      type: 'string',
      label: 'Portal ID',
    },
    {
      name: 'formId',
      type: 'string',
      label: 'Form ID',
    },
    {
      name: 'region',
      type: 'string',
      label: 'Region',
    },
    {
      name: 'id',
      type: 'string',
      label: 'ID',
    },
    {
      name: 'btnLabel',
      type: 'string',
      label: 'Button label',
    },
    {
      name: 'btnColor',
      type: 'select',
      label: 'Button color',
      options: BTN_COLORS,
    },
  ],
};

const AI_FEEDBACK = {
  name: 'feedback',
  type: 'object',
  label: 'Feedback',
  schema: [
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      changing: ['cta', 'btnText', 'btnColor'],
      options: [
        {
          label: 'External form',
          value: 'external',
        },
        {
          label: 'Scale',
          value: 'scale',
        },
      ],
    },
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
      condition: ['type'],
      conditionValues: ['external'],
    },
    {
      name: 'btnText',
      type: 'string',
      label: 'Button text',
      condition: ['type'],
      conditionValues: ['scale'],
    },
    {
      name: 'btnColor',
      type: 'select',
      label: 'Button color',
      options: BTN_COLORS,
      condition: ['type'],
      conditionValues: ['scale'],
    },
  ],
};

const MINDSTUDIO = {
  name: 'mindstudio',
  type: 'object',
  label: 'Mindstudio',
  schema: [
    {
      name: 'publicToken',
      type: 'string',
      label: 'Public token',
    },
    {
      name: 'appId',
      type: 'string',
      label: 'App ID',
    },
    {
      name: 'btnLabel',
      type: 'string',
      label: 'Button label',
    },
    {
      name: 'btnId',
      type: 'string',
      label: 'Button ID',
    },
    AI_FEEDBACK,
  ],
};

const OPEN_AI = {
  name: 'openai',
  type: 'object',
  label: 'OpenAI',
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'assistantID',
      type: 'string',
      label: 'Assistant ID',
    },
    {
      name: 'btnLabel',
      type: 'string',
      label: 'Button label',
    },
    {
      name: 'btnId',
      type: 'string',
      label: 'Button ID',
    },
    {
      name: 'initialMsg',
      type: 'string',
      label: 'Initial message',
    },
    AI_FEEDBACK,
  ],
};

const ANTHROPIC_AI = {
  name: 'anthropicai',
  type: 'object',
  label: 'AnthropicAI',
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      name: 'btnLabel',
      type: 'string',
      label: 'Button label',
    },
    {
      name: 'btnId',
      type: 'string',
      label: 'Button ID',
    },
    {
      name: 'initialMsg',
      type: 'string',
      label: 'Initial message',
    },
    {
      name: 'model',
      type: 'string',
      label: 'Model',
      info: 'If not defined, the default value from the General data will be used.',
    },
    {
      name: 'temperature',
      type: 'number',
      label: 'Temperature',
      min: 0,
      max: 1,
      defaultValue: 0,
    },
    {
      name: 'systemPrompt',
      type: 'textarea',
      label: 'System prompt',
    },
    {
      name: 'maxTokens',
      type: 'number',
      label: 'Max tokens',
      max: 8192,
      min: 1,
      info: 'Value must be between 1 and 8192. If left empty 1000 will be used.',
    },
    AI_FEEDBACK,
  ],
};

const WORLD_BACKGROUND_FIELD = {
  name: 'background',
  type: 'select',
  label: 'Background color',
  options: [
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Red2',
      value: 'red2',
    },
    {
      label: 'Green',
      value: 'green',
    },
    {
      label: 'Blue3',
      value: 'blue3',
    },
    {
      label: 'Blue4',
      value: 'blue4',
    },
    {
      label: 'Blue5',
      value: 'blue5',
    },
    {
      label: 'Blue-Orange',
      value: 'blue_orange',
    },
    {
      label: 'Red-Blue',
      value: 'red_blue',
    },
  ],
};

const SIZE_OPTIONS = [
  {
    label: 'H1',
    value: 'h1',
  },
  {
    label: 'H2',
    value: 'h2',
  },
  {
    label: 'H3',
    value: 'h3',
  },
  {
    label: 'H4',
    value: 'h4',
  },
  {
    label: 'Body',
    value: 'body-regular',
  },
  {
    label: 'Body Large',
    value: 'body-large',
  },
];

export const CHomeBanner = {
  name: 'banner',
  type: 'object',
  label: 'Banner',
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'showImage',
      type: 'boolean',
      label: 'Show image',
    },
  ],
};

export const CPageBanner = {
  name: 'pageBanner',
  type: 'object',
  label: 'Banner',
  schema: [
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      options: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Small',
          value: 'small',
        },
      ],
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
      condition: ['type'],
      conditionValues: ['small'],
    },
    { ...FORM_FIELD, condition: ['type'], conditionValues: ['small'] },
  ],
};

export const CWorldPageBanner = {
  name: 'pageBanner',
  type: 'object',
  label: 'Banner',
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
  ],
};

export const CIntroduction = {
  name: 'introduction',
  type: 'object',
  label: 'Introduction',
  img: introduction,
  schema: [
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Dark blue',
          value: 'dark-blue',
        },
        {
          label: 'Light blue',
          value: 'light-blue',
        },
      ],
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'size',
      label: 'Size',
      type: 'select',
      options: SIZE_OPTIONS,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'descSize',
      label: 'Description Size',
      type: 'select',
      options: SIZE_OPTIONS,
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
  ],
};

export const CWorldIntroduction = {
  name: 'introduction',
  type: 'object',
  label: 'Introduction',
  img: introduction,
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'worldLink',
      label: 'CTA',
    },
  ],
};

export const CMindstudio = {
  name: 'mindstudio',
  type: 'object',
  label: 'Mindstudio',
  img: introduction,
  schema: [
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Dark blue',
          value: 'dark-blue',
        },
        {
          label: 'Light blue',
          value: 'light-blue',
        },
      ],
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    MINDSTUDIO,
  ],
};

export const COpenAI = {
  name: 'openai',
  type: 'object',
  label: 'OpenAI',
  img: introduction,
  schema: [
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Dark blue',
          value: 'dark-blue',
        },
        {
          label: 'Light blue',
          value: 'light-blue',
        },
      ],
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    OPEN_AI,
  ],
};

export const CAnthropicAI = {
  name: 'anthropicai',
  type: 'object',
  label: 'AnthropicAI',
  img: introduction,
  schema: [
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Dark blue',
          value: 'dark-blue',
        },
        {
          label: 'Light blue',
          value: 'light-blue',
        },
      ],
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    ANTHROPIC_AI,
  ],
};

export const CWorldOpenAI = {
  name: 'openai',
  type: 'object',
  label: 'OpenAI',
  img: introduction,
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'worldLink',
      label: 'CTA',
    },
    OPEN_AI,
  ],
};

export const CSimpleSlider = {
  name: 'simpleSlider',
  type: 'object',
  label: 'Simple Slider',
  img: simpleSlider,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    {
      name: 'simpleSliderItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'member',
          Label: 'ID',
          required: true,
        },
      ],
    },
  ],
};

export const CPrograms = {
  name: 'programs',
  type: 'object',
  label: 'Programs',
  img: programs,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'programsItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'textarea',
          label: 'Title',
          info: 'Surround text in * to add underline (*Example text*)',
          required: true,
        },
        {
          name: 'desc',
          type: 'textarea',
          label: 'Description',
          info: 'Surround text in * to highlight (*Example text*)',
        },
        {
          name: 'img',
          type: 'img',
          label: 'Image',
        },
        {
          name: 'link',
          type: 'link',
          label: 'CTA',
        },
        FORM_FIELD,
      ],
    },
  ],
};

export const CCasesShowcase = {
  name: 'casesShowcase',
  type: 'object',
  label: 'Cases showcase',
  img: casesShowcase,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Blue',
          value: 'blue',
        },
        {
          label: 'Red',
          value: 'red',
        },
      ],
    },
    {
      name: 'casesShowcaseItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'case',
          label: 'Slug',
        },
        {
          name: 'ctaText',
          type: 'string',
          label: 'CTA text',
        },
        {
          name: 'ctaId',
          type: 'string',
          label: 'CTA ID',
        },
      ],
    },
  ],
};

export const CArticleShowcase = {
  name: 'articleShowcase',
  type: 'object',
  label: 'Article Showcase',
  img: articleShowcase,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'string',
      label: 'Description',
    },
    {
      name: 'hideDates',
      type: 'boolean',
      label: 'Hide dates',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    {
      name: 'articles',
      type: 'array',
      label: 'Articles',
      maxLength: 4,
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'article',
          label: 'Slug',
        },
      ],
    },
  ],
};

export const CPartnersSlider = {
  name: 'partnersSlider',
  type: 'object',
  label: 'Partners Slider',
  img: partnersSlider,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'numberOfLines',
      type: 'select',
      label: 'Number of lines',
      options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
      ],
    },
    {
      name: 'partners',
      type: 'array',
      label: 'Partners',
      schema: [
        {
          name: 'img',
          type: 'img',
          label: 'Image',
        },
      ],
    },
  ],
};

export const CContact = {
  name: 'contact',
  type: 'object',
  label: 'Contact',
  img: contact,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'btnLabel',
      type: 'string',
      label: 'Button label',
      required: true,
    },
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Grey',
          value: 'grey',
        },
      ],
    },
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'portalId',
      type: 'string',
      label: 'Portal ID',
      required: true,
    },
    {
      name: 'formId',
      type: 'string',
      label: 'Form ID',
      required: true,
    },
    {
      name: 'region',
      type: 'string',
      label: 'Region',
      required: true,
    },
    {
      name: 'id',
      type: 'string',
      label: 'ID',
      required: true,
    },
    {
      name: 'btnColor',
      type: 'select',
      label: 'Button color',
      options: BTN_COLORS,
    },
    {
      name: 'hideImg',
      type: 'boolean',
      label: 'Hide Image',
    },
  ],
};

export const CQuoteSlider = {
  name: 'quoteSlider',
  type: 'object',
  label: 'Quote Slider',
  img: quoteSlider,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'quoteSliderItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'quote',
          type: 'textarea',
          label: 'Quote',
          required: true,
        },
        {
          name: 'img',
          type: 'img',
          label: 'Image',
        },
        {
          name: 'cta',
          type: 'link',
          label: 'CTA',
        },
      ],
    },
  ],
};

export const CTitle = {
  name: 'title',
  type: 'object',
  label: 'Title',
  img: title,
  schema: [
    {
      name: 'label',
      type: 'string',
      label: 'Label',
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      info: 'Surround text in * to add underline (*Example text*)',
      required: true,
    },
    {
      name: 'size',
      label: 'Size',
      type: 'select',
      options: SIZE_OPTIONS,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
  ],
};

export const CWorldTitle = {
  name: 'title',
  type: 'object',
  label: 'Title',
  img: title,
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'label',
      type: 'string',
      label: 'Label',
    },
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      info: 'Surround text in * to add underline (*Example text*)',
      required: true,
    },
  ],
};

export const CResult = {
  name: 'result',
  type: 'object',
  label: 'Result',
  img: result,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      options: [
        {
          label: 'Grey',
          value: 'grey',
        },
        {
          label: 'Blue',
          value: 'blue',
        },
        {
          label: 'Red',
          value: 'red',
        },
      ],
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    FORM_FIELD,
    {
      name: 'resultItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
        {
          name: 'desc',
          type: 'string',
          label: 'Description',
        },
      ],
    },
  ],
};

export const CWorldResult = {
  name: 'result',
  type: 'object',
  label: 'Result',
  img: result,
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'worldLink',
      label: 'CTA',
    },
    FORM_FIELD,
    {
      name: 'resultItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
        {
          name: 'desc',
          type: 'string',
          label: 'Description',
        },
      ],
    },
  ],
};

export const CPeopleShowcase = {
  name: 'peopleShowcase',
  type: 'object',
  label: 'People Showcase',
  img: peopleShowcase,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    {
      name: 'peopleShowcaseItems',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: '',
          type: 'ref',
          entity: 'member',
          Label: 'ID',
          required: true,
        },
      ],
    },
  ],
};

export const CMarkdown = {
  name: 'markdown',
  type: 'object',
  label: 'Markdown',
  img: markdown,
  schema: [
    {
      name: 'body',
      type: 'markdown',
      label: 'Body',
    },
  ],
};

export const CPromo = {
  name: 'promo',
  type: 'object',
  label: 'Promo',
  img: promo,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'string',
      label: 'Description',
    },
    {
      name: 'info',
      type: 'string',
      label: 'Info',
    },
    {
      name: 'label',
      type: 'string',
      label: 'Label',
    },
    {
      name: 'img',
      type: 'img',
      label: 'Background',
    },
    {
      name: 'symbol',
      type: 'select',
      label: 'Symbol',
      options: [
        {
          label: 'Simple',
          value: 'simple',
        },
        {
          label: 'Full',
          value: 'full',
        },
        {
          label: 'World',
          value: 'world',
        },
      ],
    },
    {
      name: 'video',
      type: 'string',
      label: 'Video source',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
  ],
};

export const CImage = {
  name: 'image',
  type: 'object',
  label: 'Image',
  schema: [
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'full',
      type: 'boolean',
      label: 'Full width',
    },
    {
      name: 'size',
      type: 'select',
      label: 'Size',
      options: [
        {
          label: 'Large',
          value: 'large',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Small',
          value: 'small',
        },
        {
          label: 'Original',
          value: undefined,
        },
      ],
    },
  ],
};

export const CWorldImage = {
  name: 'image',
  type: 'object',
  label: 'Image',
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'img',
      type: 'img',
      label: 'Image',
    },
    {
      name: 'full',
      type: 'boolean',
      label: 'Full width',
    },
    {
      name: 'size',
      type: 'select',
      label: 'Size',
      options: [
        {
          label: 'Large',
          value: 'large',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Small',
          value: 'small',
        },
        {
          label: 'Original',
          value: undefined,
        },
      ],
    },
  ],
};

export const CWorldHomeBanner = {
  name: 'banner',
  type: 'object',
  label: 'Banner',
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      info: 'Surround text in * to add strong tags (*Example text*)',
      required: true,
    },
    {
      name: 'img',
      type: 'img',
      label: 'Background',
    },
    {
      name: 'ctaLabel',
      type: 'string',
      label: 'CTA label',
    },
  ],
};

export const CWorldCommitment = {
  name: 'commitment',
  type: 'object',
  label: 'Commitment',
  img: commitment,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      info: 'Surround a word in * to add highligh (*Example*)',
      required: true,
    },
    {
      name: 'label',
      type: 'string',
      label: 'Label',
    },
    {
      name: 'img',
      type: 'img',
      label: 'Background',
    },
  ],
};

export const CWorldMission = {
  name: 'mission',
  type: 'object',
  label: 'Mission',
  img: mission,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'desc',
      type: 'string',
      label: 'Description',
    },
    FORM_FIELD,
    {
      name: 'usps',
      type: 'array',
      label: 'USPs',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
      ],
    },
    {
      name: 'img',
      type: 'img',
      label: 'Background',
    },
  ],
};

export const CWorldProduct = {
  name: 'product',
  type: 'object',
  label: 'Product',
  img: product,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'label',
      type: 'string',
      label: 'Label',
    },
    {
      name: 'desc',
      type: 'string',
      label: 'Description',
    },
    {
      name: 'video',
      type: 'string',
      label: 'Video',
    },
    {
      name: 'videoLabel',
      type: 'string',
      label: 'Video button label',
    },
    {
      name: 'videoID',
      type: 'string',
      label: 'Video button ID',
    },
    {
      name: 'images',
      type: 'array',
      label: 'Images',
      maxLength: 2,
      schema: [
        {
          name: '',
          type: 'img',
          label: 'Image',
        },
      ],
    },
    {
      name: 'img',
      type: 'img',
      label: 'Background',
    },
  ],
};

export const CWorldAudience = {
  name: 'audience',
  type: 'object',
  label: 'Audience',
  img: Audience,
  schema: [
    {
      name: 'img',
      type: 'img',
      label: 'Background image',
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'textarea',
          label: 'Title',
          required: true,
        },
        {
          name: 'desc',
          type: 'textarea',
          label: 'Description',
        },
        {
          name: 'cta',
          type: 'worldLink',
          label: 'CTA',
        },
        FORM_FIELD,
      ],
    },
  ],
};

export const CWorldTestimonials = {
  name: 'testimonials',
  type: 'object',
  label: 'Testimonials',
  img: Testimonials,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
      required: true,
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'textarea',
          label: 'Title',
          required: true,
        },
        {
          name: 'desc',
          type: 'textarea',
          label: 'Description',
        },
      ],
    },
  ],
};

export const CFAQ = {
  name: 'faq',
  type: 'object',
  label: 'FAQ',
  img: faq,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'side',
      type: 'select',
      label: 'Side',
      options: [
        {
          label: 'Right',
          value: 'right',
        },
        {
          label: 'Left',
          value: 'left',
        },
      ],
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'question',
          type: 'string',
          label: 'Question',
          required: true,
        },
        {
          name: 'answer',
          type: 'textarea',
          label: 'Answer',
          required: true,
        },
      ],
    },
  ],
};
export const CWorldFAQ = {
  name: 'faq',
  type: 'object',
  label: 'FAQ',
  schema: [
    WORLD_BACKGROUND_FIELD,
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'side',
      type: 'select',
      label: 'Side',
      options: [
        {
          label: 'Right',
          value: 'right',
        },
        {
          label: 'Left',
          value: 'left',
        },
      ],
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'question',
          type: 'string',
          label: 'Question',
          required: true,
        },
        {
          name: 'answer',
          type: 'textarea',
          label: 'Answer',
          required: true,
        },
      ],
    },
  ],
};

export const CAICarousel = {
  name: 'aiCarousel',
  type: 'object',
  label: 'AI Carousel',
  img: aiCarousel,
  schema: [
    {
      name: 'title',
      type: 'textarea',
      label: 'Title',
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'textarea',
          label: 'Title',
        },
        {
          name: 'desc',
          type: 'textarea',
          label: 'Description',
        },
        {
          name: 'img',
          type: 'img',
          label: 'Image',
        },
        {
          name: 'type',
          type: 'select',
          label: 'Type',
          options: [
            {
              label: 'OpenAI',
              value: 'openAI',
            },
            {
              label: 'Mindstudio',
              value: 'mindstudio',
            },
            {
              label: 'AnthropicAI',
              value: 'anthropicai',
            },
          ],
          changing: ['openai', 'mindstudio', 'anthropicai'],
        },
        {
          ...MINDSTUDIO,
          condition: ['type'],
          conditionValues: ['mindstudio'],
        },
        {
          ...OPEN_AI,
          condition: ['type'],
          conditionValues: ['openAI'],
        },
        {
          ...ANTHROPIC_AI,
          condition: ['type'],
          conditionValues: ['anthropicai'],
        },
      ],
    },
  ],
};

export const CWorldSupport = {
  name: 'support',
  type: 'object',
  label: 'Support',
  img: supportWorld,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'titleTag',
      type: 'select',
      label: 'Title element',
      options: [
        {
          label: 'H1',
          value: 'h1',
        },
        {
          label: 'H2',
          value: 'h2',
        },
      ],
    },
    {
      name: 'subtitle',
      type: 'string',
      label: 'Subtitle',
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'worldLink',
      label: 'CTA',
    },
    {
      name: 'sections',
      type: 'array',
      label: 'Sections',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
        {
          name: 'items',
          type: 'array',
          label: 'Items',
          schema: [
            {
              name: 'question',
              type: 'textarea',
              label: 'Question',
              required: true,
            },
            {
              name: 'answer',
              type: 'textarea',
              label: 'Answer',
              required: true,
            },
          ],
        },
      ],
    },
  ],
};

export const CSupport = {
  name: 'support',
  type: 'object',
  label: 'Support',
  img: support,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'titleTag',
      type: 'select',
      label: 'Title element',
      options: [
        {
          label: 'H1',
          value: 'h1',
        },
        {
          label: 'H2',
          value: 'h2',
        },
      ],
    },
    {
      name: 'subtitle',
      type: 'string',
      label: 'Subtitle',
    },
    {
      name: 'desc',
      type: 'textarea',
      label: 'Description',
    },
    {
      name: 'cta',
      type: 'link',
      label: 'CTA',
    },
    {
      name: 'sections',
      type: 'array',
      label: 'Sections',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
        {
          name: 'items',
          type: 'array',
          label: 'Items',
          schema: [
            {
              name: 'question',
              type: 'textarea',
              label: 'Question',
              required: true,
            },
            {
              name: 'answer',
              type: 'textarea',
              label: 'Answer',
              required: true,
            },
          ],
        },
      ],
    },
  ],
};

export const CEvents = {
  name: 'events',
  type: 'object',
  label: 'Events',
  img: events,
  schema: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      required: true,
    },
    {
      name: 'background',
      type: 'select',
      label: 'Background color',
      default: 'white',
      options: [
        {
          value: 'white',
          label: 'White',
        },
        {
          value: 'grey',
          label: 'Grey',
        },
      ],
    },
    {
      name: 'items',
      type: 'array',
      label: 'Items',
      schema: [
        {
          name: 'title',
          type: 'string',
          label: 'Title',
          required: true,
        },
        {
          name: 'desc',
          type: 'string',
          label: 'Description',
        },
        {
          name: 'location',
          type: 'string',
          label: 'Location',
        },
        {
          name: 'price',
          type: 'string',
          label: 'Price',
        },
        {
          name: 'date',
          type: 'date',
          label: 'Date',
        },
        {
          name: 'img',
          type: 'img',
          label: 'Image',
        },
        {
          name: 'cta',
          type: 'link',
          label: 'CTA',
        },
        FORM_FIELD,
      ],
    },
  ],
};
