/* eslint-disable import/no-cycle */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable react/jsx-props-no-spreading */
import { CheckIcon, Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Stack,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Control,
  FormState,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';
import { FiChevronDown, FiInfo } from 'react-icons/fi';

import { isJsonString } from '../../core/helpers';
import ArrayField from './ArrayField';
import ComponentField from './ComponentField';
import ModulePartField from './ModulePartField';
import RHFDateField from './RHFDateField';
import RHFImageField from './RHFImageField';
import RHFImageNameField from './RHFImageNameField';
import RHFInnerRef from './RHFInnerRef';
import RHFMarkdownField from './RHFMarkdownField';
import RHFPGMarkdownField from './RHFPGMarkdownField';
import RHFRefField from './RHFRefField';
import RHFTagsField from './RHFTagsField';
import RHLifeQuestions from './RHLifeQuestions';
import RHLocationField from './RHLocationField';
import RHMultiSelect from './RHMultiSelect';
import RHPgLifeQuestions from './RHPgLifeQuestions';
import RHSubscriptions from './RHSubscriptions';
import { VATField } from './VATField';

interface IProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  unregister: UseFormUnregister<any>;
  field: any;
  getFieldState: UseFormGetFieldState<any>;
  getValues: UseFormGetValues<any>;
  formState: FormState<any>;
  setValue: UseFormSetValue<any>;
  path?: string;
  isFilter?: boolean;
  trigger: any;
}

export default function Field({
  control,
  register,
  unregister,
  field,
  getFieldState,
  getValues,
  formState,
  setValue,
  isFilter = false,
  path = '',
  trigger,
}: IProps) {
  const newPath = `${path}${field.name}`;
  const defaultValue = useRef(getValues(newPath));
  const [disable, setDisable] = useState(
    newPath.includes('.action.props.') && defaultValue.current === undefined,
  );
  let property = '';

  useEffect(() => {
    if (field.readOnly) {
      unregister(newPath, {
        keepDefaultValue: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!field.defaultValue || defaultValue.current !== undefined) return;
    setValue(newPath, field.defaultValue, { shouldDirty: false });
  }, []);

  const checkError = useCallback(() => {
    if (field.type === 'modulePart')
      return (
        getFieldState(`${newPath.replace('.undefined', '')}.moduleSlug`).error ||
        getFieldState(`${newPath.replace('.undefined', '')}.partId`).error
      );
    return getFieldState(newPath).error;
  }, [formState.errors]);

  if (field.condition && field.conditionValues) {
    if (!field.conditionValues.includes(getValues(`${path}${field.condition}`))) {
      const values = getValues((path.length > 0 ? path : undefined) as any) || {};
      if (Object.keys(values).length > 0) {
        delete values[field.name];
      }

      return null;
    }
  }

  if (field.condition && !field.conditionValues && field.schema) {
    if (
      !field.condition.some((el: string) =>
        Object.keys(field.schema).includes(getValues(`${path}${el}`)),
      )
    )
      return null;

    property = Object.keys(field.schema).filter((el) =>
      field.condition.map((val: string) => getValues(`${path}${val}`)).includes(el),
    )[0];

    if (getValues(newPath)) {
      Object.keys(getValues(newPath))
        .filter((el) => !field.schema[property].map((val: any) => val.name).includes(el))
        .forEach((key) => {
          delete getValues(newPath)[key];
        });
    }
  }

  if (field.hidden) return null;

  return field.type === 'array' ? (
    <ArrayField
      {...{
        control,
        register,
        unregister,
        formState,
        setValue,
        field,
        getFieldState,
        getValues,
        path,
        trigger,
      }}
    />
  ) : field.type === 'object' ? (
    field.collapsible ? (
      <Accordion
        allowToggle
        _notLast={{
          mb: 5,
        }}
      >
        <AccordionItem boxShadow="0 0 8px rgba(0,0,0,0.2)" borderRadius={6} p={2}>
          <AccordionButton borderRadius={6}>
            <Heading fontSize={24} display="flex" alignItems="center">
              {field.label}
            </Heading>
            <AccordionIcon ml="auto" />
          </AccordionButton>
          <AccordionPanel mt={5}>
            {field.condition && !Array.isArray(field.schema)
              ? field.schema[property]?.map((innerField: any) => (
                  <Field
                    key={innerField.name}
                    {...{
                      control,
                      register,
                      field: innerField,
                      getFieldState,
                      getValues,
                      setValue,
                      path: `${newPath}.`,
                      trigger,
                      unregister,
                      formState,
                    }}
                  />
                ))
              : field.schema.map((innerField: any) => (
                  <Field
                    key={innerField.name}
                    {...{
                      control,
                      register,
                      field: innerField,
                      getFieldState,
                      getValues,
                      setValue,
                      path: `${newPath}.`,
                      trigger,
                      unregister,
                      formState,
                    }}
                  />
                ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      <Box
        bg="white"
        p={5}
        _notLast={{
          mb: 5,
        }}
        borderRadius={6}
        boxShadow="0 0 8px rgba(0,0,0,0.2)"
        flex="1 1 auto"
      >
        {!field.label ? null : (
          <Heading fontSize={30} mb={5}>
            {field.label}
          </Heading>
        )}
        {field.condition && !Array.isArray(field.schema)
          ? field.schema[property]?.map((innerField: any) => (
              <Field
                key={innerField.name}
                {...{
                  control,
                  register,
                  field: innerField,
                  getFieldState,
                  getValues,
                  setValue,
                  path: `${newPath}.`,
                  trigger,
                  unregister,
                  formState,
                }}
              />
            ))
          : field.schema.map((innerField: any) => (
              <Field
                key={innerField.name}
                {...{
                  control,
                  register,
                  field: innerField,
                  getFieldState,
                  getValues,
                  setValue,
                  path: `${newPath}.`,
                  trigger,
                  unregister,
                  formState,
                }}
              />
            ))}
      </Box>
    )
  ) : field.type === 'string' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      {!field.info ? null : (
        <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
          <Tag mx={3} mb={2} p={1}>
            <Icon fontSize={15} as={FiInfo} />
          </Tag>
        </Tooltip>
      )}
      {field.readOnly ? (
        <input
          className={`input__field${checkError() ? ' error' : ''}`}
          id={newPath}
          value={field.defaultValue}
          readOnly={field.readOnly}
        />
      ) : (
        <input
          className={`input__field${checkError() ? ' error' : ''}`}
          id={newPath}
          {...register(newPath, {
            validate: field.validate || null,
            required: field.required ? newPath : false,
            disabled: disable,
          })}
        />
      )}
      {newPath.includes('.action.props.') ? (
        <Box mt={2} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}-skip`}>
            Disable
            <input
              className="input__checkbox"
              id={`${newPath}-skip`}
              type="checkbox"
              checked={disable}
              onChange={(e) => {
                setDisable(e.target.checked);
              }}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
      ) : null}
    </Box>
  ) : field.type === 'number' || field.type === 'int' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      {!field.info ? null : (
        <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
          <Tag mx={3} mb={2} p={1}>
            <Icon fontSize={15} as={FiInfo} />
          </Tag>
        </Tooltip>
      )}
      <input
        className={`input__field${checkError() ? ' error' : ''}`}
        id={newPath}
        type="number"
        {...register(newPath, {
          valueAsNumber: true,
          required: field.required ? newPath : false,
          validate: field.validate || null,
          disabled: disable,
          onChange: field.changing
            ? () => {
                field.changing.forEach((el: string) => {
                  trigger(`${path}${el}`);
                });
              }
            : undefined,
        })}
        step={field.type === 'int' ? 1 : 'any'}
        min={field?.min}
        max={field?.max}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
      />
      {newPath.includes('.action.props.') ? (
        <Box mt={2} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}-skip`}>
            Disable
            <input
              className="input__checkbox"
              id={`${newPath}-skip`}
              type="checkbox"
              checked={disable}
              onChange={(e) => {
                setDisable(e.target.checked);
              }}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
      ) : null}
    </Box>
  ) : field.type === 'boolean' ? (
    <Box
      _notLast={{
        mb: 7,
      }}
      className="input input--checkbox"
    >
      <label className="input__checkbox-label" htmlFor={newPath}>
        {field.label}
        <input
          className="input__checkbox"
          id={newPath}
          type="checkbox"
          {...register(newPath, {
            required: field.required ? newPath : false,
            onChange: field.changing
              ? () => {
                  field.changing.forEach((el: string) => {
                    trigger(`${path}${el}`);
                  });
                }
              : undefined,
          })}
        />
        <CheckIcon className="input__checkmark-icon" width={5} height={5} />
        <span className="input__checkmark" />
      </label>
    </Box>
  ) : field.type === 'textarea' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      {!field.info ? null : (
        <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
          <Tag mx={3} mb={2} p={1}>
            <Icon fontSize={15} as={FiInfo} />
          </Tag>
        </Tooltip>
      )}
      <textarea
        className={`input__field${checkError() ? ' error' : ''}`}
        rows={field.rows || 6}
        id={newPath}
        {...register(newPath, {
          required: field.required ? newPath : false,
        })}
      />
    </Box>
  ) : field.type === 'pgMarkdown' ? (
    <RHFPGMarkdownField
      path={newPath}
      field={field}
      checkError={checkError}
      register={register}
      setValue={setValue}
      getValues={getValues}
    />
  ) : field.type === 'multiSelect' ? (
    <RHMultiSelect
      newPath={newPath}
      path={path}
      field={field}
      checkError={checkError}
      setValue={setValue}
      control={control}
      getValues={getValues}
    />
  ) : field.type === 'location' ? (
    <RHLocationField path={newPath} field={field} setValue={setValue} control={control} />
  ) : field.type === 'lifeQuestions' ? (
    <RHLifeQuestions path={newPath} setValue={setValue} control={control} />
  ) : field.type === 'pgLifeQuestions' ? (
    <RHPgLifeQuestions path={newPath} setValue={setValue} control={control} />
  ) : field.type === 'subscriptions' ? (
    <RHSubscriptions path={newPath} setValue={setValue} getValues={getValues} />
  ) : field.type === 'tagsField' ? (
    <RHFTagsField path={newPath} field={field} setValue={setValue} control={control} />
  ) : field.type === 'markdown' ? (
    <RHFMarkdownField
      path={newPath}
      field={field}
      checkError={checkError}
      register={register}
      setValue={setValue}
      getValues={getValues}
    />
  ) : field.type === 'json' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      <textarea
        className={`input__field${checkError() ? ' error' : ''}`}
        rows={6}
        id={newPath}
        {...register(newPath, {
          setValueAs: (value: string) => {
            if (!isJsonString(value)) return 'false';
            return JSON.parse(value);
          },
          validate: (value: string) => !value || typeof value === 'object' || newPath,
          required: field.required ? newPath : false,
        })}
      />
    </Box>
  ) : field.type === 'select' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      <div className="input__wrapper">
        <select
          className={`input__field input__field--select${checkError() ? ' error' : ''}`}
          id={newPath}
          {...register(newPath, {
            required: field.required ? newPath : false,
            onChange: field.changing
              ? () => {
                  field.changing.forEach((el: string) => {
                    trigger(`${path}${el}`);
                  });
                }
              : undefined,
          })}
        >
          <option value="">{field.placeholder || field.label}</option>
          {field.options
            .sort((a: any, b: any) => {
              const labelA = a.label.toUpperCase();
              const labelB = b.label.toUpperCase();

              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            })
            .map((option: any) => (
              <option value={option.value} key={option.label}>
                {option.label}
              </option>
            ))}
        </select>
        <Icon className="input__icon icon" as={FiChevronDown} />
      </div>
    </Box>
  ) : field.type === 'img' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <div>{field.label}</div>
      <RHFImageField
        className={`${checkError() ? ' error' : ''}`}
        setValue={setValue}
        path={newPath}
        control={control}
        required={field.required ? newPath : false}
      />
    </Box>
  ) : field.type === 'date' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <Heading mb={1} fontSize={18} fontWeight={500}>
        <div>{field.label}</div>
      </Heading>
      <RHFDateField
        className={`${checkError() ? ' error' : ''}`}
        path={newPath}
        field={field}
        register={register}
        getValues={getValues}
        setValue={setValue}
      />
    </Box>
  ) : field.type === 'date-slot' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <Heading mb={1} fontSize={18} fontWeight={500}>
        <div>{field.label}</div>
      </Heading>
      <RHFDateField
        className={`${checkError() ? ' error' : ''}`}
        path={newPath}
        field={field}
        register={register}
        getValues={getValues}
        setValue={setValue}
        type="slot"
      />
    </Box>
  ) : field.type === 'ref' ? (
    <Box
      _notLast={{
        mb: isFilter ? 0 : 5,
      }}
    >
      <Flex alignItems="center">
        {!field.label ? null : (
          <Heading mb={1} fontSize={18} fontWeight={500}>
            {field.label}
          </Heading>
        )}
        {!field.info ? null : (
          <Tooltip label={field.info} hasArrow p={5} borderRadius={5}>
            <Tag mx={3} p={1}>
              <Icon fontSize={15} as={FiInfo} />
            </Tag>
          </Tooltip>
        )}
      </Flex>
      <RHFRefField
        className={`${checkError() ? ' error' : ''}`}
        style={{
          display: isFilter ? 'flex' : 'block',
          minWidth: isFilter ? 200 : 0,
        }}
        setValue={setValue}
        path={newPath}
        field={field}
        trigger={trigger}
        control={control}
        required={field.required ? newPath : false}
        entity={field.entity}
      />
    </Box>
  ) : field.type === 'component' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <Heading mb={5} fontSize={28}>
        {field.label}
      </Heading>
      <ComponentField
        control={control}
        path={newPath}
        field={field}
        register={register}
        unregister={unregister}
        formState={formState}
        getFieldState={getFieldState}
        getValues={getValues}
        setValue={setValue}
        trigger={trigger}
      />
    </Box>
  ) : field.type === 'innerRef' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <Heading mb={1} fontSize={18} fontWeight={500}>
        {field.label}
      </Heading>
      <RHFInnerRef
        className={`${checkError() ? ' error' : ''}`}
        path={newPath}
        control={control}
        required={field.required ? newPath : false}
        placeholder={field.label}
        options={getValues(field.fieldPath)
          .map((el: any) => el.id)
          .filter((el: any) => el)}
      />
    </Box>
  ) : field.type === 'modulePart' ? (
    <ModulePartField
      className={`${checkError() ? ' error' : ''}`}
      setValue={setValue}
      path={newPath}
      control={control}
      required={field.required ? newPath : false}
    />
  ) : field.type === 'link' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <div>{field.label}</div>
      <Box p={5} border="1px solid lightgray" my={5}>
        <Box mb={5}>
          <label htmlFor={`${newPath}.text`}>Text</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.text`}
            {...register(`${newPath}.text`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.href`}>Href</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.href`}
            {...register(`${newPath}.href`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.id`}>ID</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.id`}
            {...register(`${newPath}.id`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.color`}>Color</label>
          <div className="input__wrapper">
            <select
              className="input__field input__field--select"
              id={`${newPath}.color`}
              {...register(`${newPath}.color`)}
            >
              <option value="">Color</option>
              <option value="dark">Dark</option>
              <option value="light">Light</option>
              <option value="primary">Blue</option>
              <option value="secondary">Red</option>
            </select>
            <Icon className="input__icon icon" as={FiChevronDown} />
          </div>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.hasArrow`}>
            Has Arrow
            <input
              className="input__checkbox"
              id={`${newPath}.hasArrow`}
              type="checkbox"
              {...register(`${newPath}.hasArrow`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.isDownload`}>
            Is download
            <input
              className="input__checkbox"
              id={`${newPath}.isDownload`}
              type="checkbox"
              {...register(`${newPath}.isDownload`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.newTab`}>
            New tab
            <input
              className="input__checkbox"
              id={`${newPath}.newTab`}
              type="checkbox"
              {...register(`${newPath}.newTab`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
      </Box>
    </Box>
  ) : field.type === 'worldLink' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <div>{field.label}</div>
      <Box p={5} border="1px solid lightgray" my={5}>
        <Box mb={5}>
          <label htmlFor={`${newPath}.text`}>Text</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.text`}
            {...register(`${newPath}.text`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.href`}>Href</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.href`}
            {...register(`${newPath}.href`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.id`}>ID</label>
          <input
            className={`input__field${checkError() ? ' error' : ''}`}
            id={`${newPath}.id`}
            {...register(`${newPath}.id`)}
          />
        </Box>
        <Box mb={5}>
          <label htmlFor={`${newPath}.color`}>Color</label>
          <div className="input__wrapper">
            <select
              className="input__field input__field--select"
              id={`${newPath}.color`}
              {...register(`${newPath}.color`)}
            >
              <option value="light">Light</option>
              <option value="link">Link</option>
            </select>
            <Icon className="input__icon icon" as={FiChevronDown} />
          </div>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.hasArrow`}>
            Has Arrow
            <input
              className="input__checkbox"
              id={`${newPath}.hasArrow`}
              type="checkbox"
              {...register(`${newPath}.hasArrow`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.isDownload`}>
            Is download
            <input
              className="input__checkbox"
              id={`${newPath}.isDownload`}
              type="checkbox"
              {...register(`${newPath}.isDownload`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
        <Box mb={5} className="input input--checkbox">
          <label className="input__checkbox-label" htmlFor={`${newPath}.newTab`}>
            New tab
            <input
              className="input__checkbox"
              id={`${newPath}.newTab`}
              type="checkbox"
              {...register(`${newPath}.newTab`)}
            />
            <CheckIcon className="input__checkmark-icon" width={5} height={5} />
            <span className="input__checkmark" />
          </label>
        </Box>
      </Box>
    </Box>
  ) : field.type === 'time-slot' ? (
    <Stack flexDirection="row" alignItems="center" gap={5}>
      <Box>
        <label htmlFor="day">Day</label>
        <div className="input__wrapper">
          <select
            style={{
              width: 200,
            }}
            className="input__field input__field--select"
            id="day"
            {...register(`day`, {
              required: true,
            })}
          >
            <option value="">Day</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
          <Icon className="input__icon icon" as={FiChevronDown} />
        </div>
      </Box>
      <Box
        style={{
          marginBottom: 5,
        }}
      >
        <label htmlFor="from">From</label>
        <div className="input__wrapper">
          <select
            style={{
              width: 150,
            }}
            className="input__field input__field--select"
            id="from"
            {...register(`from`, {
              required: true,
            })}
          >
            <option value="">From</option>
            {[...Array(24).keys()].map((h) => (
              <option
                key={`${h.toString().length === 1 ? `0${h.toString()}` : h}:00`}
                value={`${h.toString().length === 1 ? `0${h.toString()}` : h}:00`}
              >
                {h.toString().length === 1 ? `0${h.toString()}` : h}:00
              </option>
            ))}
          </select>
          <Icon className="input__icon icon" as={FiChevronDown} />
        </div>
      </Box>
      <Box
        style={{
          marginBottom: 5,
        }}
      >
        <label htmlFor="to">To</label>
        <div className="input__wrapper">
          <select
            style={{
              width: 150,
            }}
            className="input__field input__field--select"
            id="to"
            {...register(`to`, {
              required: true,
            })}
          >
            <option value="">To</option>
            {[...Array(24).keys()].map((h) => (
              <option
                key={`${h.toString().length === 1 ? `0${h.toString()}` : h}:00`}
                value={`${h.toString().length === 1 ? `0${h.toString()}` : h}:00`}
              >
                {h.toString().length === 1 ? `0${h.toString()}` : h}:00
              </option>
            ))}
          </select>
          <Icon className="input__icon icon" as={FiChevronDown} />
        </div>
      </Box>
    </Stack>
  ) : field.type === 'imgFileName' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      <RHFImageNameField
        setValue={setValue}
        path={newPath}
        field={field}
        getValues={getValues}
        control={control}
        register={register}
      />
    </Box>
  ) : field.type === 'dependent' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <label htmlFor={newPath}>{field.label}</label>
      <RHMultiSelect
        newPath={newPath}
        path={path}
        field={field}
        checkError={checkError}
        setValue={setValue}
        control={control}
      />
      {/* <div className="input__wrapper">
        <select
          className={`input__field input__field--select${checkError() ? ' error' : ''}`}
          id={newPath}
          {...register(newPath, {
            required: field.required ? newPath : false,
            onChange: field.changing
              ? () => {
                  field.changing.forEach((el: string) => {
                    trigger(`${path}${el}`);
                  });
                }
              : undefined,
          })}
        >
          <option value="">{field.placeholder || field.label}</option>
          {getValues(path + field.dependency)[field.name]?.map((option: any) => (
            <option value={option._id} key={option._id}>
              {option._id}
            </option>
          ))}
          {/* {field.options.map((option: any) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon className="input__icon icon" as={FiChevronDown} />
      </div> */}
    </Box>
  ) : field.type === 'vat_field' ? (
    <Box
      _notLast={{
        mb: 5,
      }}
    >
      <VATField
        getValues={getValues}
        newPath={newPath}
        label={field.label}
        checkError={checkError}
        path={path}
      />
    </Box>
  ) : null;
}
